import { Elm } from "./Main.elm";
import logo from "./assets/logo.svg";
import screenshot from "./assets/screenshot.png";
import fill from "./assets/fill-bw.png";
import macos from "./assets/macos.svg";
import linux from "./assets/linux.svg";
import windows from "./assets/windows.svg";
import pi from "./assets/pi.svg";
import github from "./assets/github.svg";
import icon from "./assets/icon.svg";
import translate from "./assets/translate.png";

Elm.Main.init({
  flags: {
    logo,
    screenshot,
    fill,
    macos,
    linux,
    windows,
    pi,
    github,
    icon,
    translate
  }
});
